<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">Sampling</h2>

    <input-sampling-bulk/>
  </div>
</template>

<script>
import InputSamplingBulk from "@/components/Farm/Sampling/InputSamplingBulk";
export default {
  name: "InputSamplingForm",
  components: {
    InputSamplingBulk
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
  },
}
</script>
