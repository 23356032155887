<template>
  <div class="input-data">
    <div>
      <section>

        <div class="is-flex is-justify-content-space-between is-align-items-flex-end mb-2">
          <div class="is-flex is-flex-wrap-wrap">
            <sg-select :items="[{block_name: $t('all'), prepend: false},...selectedFarm.blocks]"
                       type="is-secondary is-light"
                       :prepend="$t('farm.block')"
                       class="mr-2 mb-2"
                       label="block_name"
                       v-model="selectedBlock"
                       :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

            <b-datepicker
                class="mr-2"
                :placeholder="$t('date')"
                icon="calendar-text"
                locale="id-ID"
                v-model="selectedDate"/>
          </div>

          <b-button type="is-text" class="has-text-info has-shadow" @click="resetForm" icon-left="sync">
            Reset Form
          </b-button>
        </div>

        <div class="b-table custom-table" v-if="filteredData.length">
          <div class="table-wrapper has-sticky-header" :class="{embed: embed}">
            <table class="table is-bordered">
              <thead>
              <tr class="header-row">
                <th class="has-text-centered">{{ $t('farm.pond') }}</th>
                <th>MBW (gr)<span class="has-text-danger">*</span></th>
                <th>SR (%)<span class="has-text-danger">*</span></th>
                <th v-if="isPro">{{ $t('sampling.hollow') }}</th>
                <th v-if="isPro">{{ $t('sampling.molting') }}</th>
                <th v-if="isPro">{{ $t('sampling.pale') }}</th>
                <th v-if="isPro">{{ $t('sampling.undersize') }}</th>
                <th v-if="isPro">{{ $t('sampling.red_leg') }}</th>
                <th v-if="isPro">{{ $t('sampling.red_tail') }}</th>
              </tr>
              </thead>
              <tbody v-if="canAddSampling">
              <tr v-for="(item, key) in filteredData" :key="key">
                <td class="has-text-centered">
                  {{ item.pond.name }}
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm))"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" placeholder="Input MBW" expanded
                             step="any" v-model.number="item.mbw"></b-input>
                  </b-field>
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 1)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" placeholder="Input SR" expanded
                             step="any" v-model.number="item.sr"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 2)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.hollow')" expanded step="any" v-model.number="item.kropos"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 3)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.molting')" expanded step="any" v-model.number="item.molting"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 4)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.pale')" expanded
                             step="any" v-model.number="item.warna_pucat"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 5)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.undersize')" expanded step="any" v-model.number="item.blantik"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 6)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.red_leg')" expanded step="any" v-model.number="item.kaki_merah"></b-input>
                  </b-field>
                </td>
                <td class="p-0" v-if="isPro">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 7)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             type="number" :placeholder="$t('sampling.red_tail')" expanded step="any" v-model.number="item.ekor_merah"></b-input>
                  </b-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="has-text-danger" v-else>No Ponds have been stocked yet <span
            v-if="selectedBlock && selectedBlock.block_id">on Block {{ selectedBlock.block_name }}</span></p>
      </section>

      <footer class="is-flex is-justify-content-flex-end mt-3" v-if="filteredData.length">
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapGetters} from "vuex";

export default {
  name: "InputSamplingBulk",
  components: {SgSelect},
  computed: {
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    canAddSampling() {
      return this.$store.getters["farm/canAddSampling"]
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },
    filteredPondsInput() {
      return this.selectedFarm.ponds.filter(e => e.status >= 2)
    },
    totalAnco() {
      return this.data.length ? this.data.filter(e => e.pond).reduce((a, b) => b.pond.anco > a ? b.pond.anco : a, 0) : 0
    },
    filteredData() {
      let data = this.data.filter(e => e.pond.status >= 2)
      if (this.selectedBlock && this.selectedBlock.block_id) data = data.filter(e => e.pond.block_id === this.selectedBlock.block_id && e.pond.status >= 2)
      return data
    },
    embed () {
      return this.$route.query.output === 'embed'
    },
    ...mapGetters('farm', [
      'isPro',
    ]),
  },
  data: () => {
    return {
      selectedDate: new Date(),
      times: [],
      selectedBlock: null,
      data: [],

      selectedInput: 0,
      maxInput: 0,
      maxInputForm: 8,
    }
  },
  watch: {
    'selectedFarm.blocks'(value) {
      this.selectedBlock = value[0]
    },
    'selectedFarm.ponds'() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {

    handleFocus(n) {
      this.selectedInput = n
      this.maxInput = this.$refs[`input-form`].length - 1
    },

    handleArrowRight() {
      if (this.selectedInput >= this.maxInput) this.selectedInput = -1
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput + 1]
      this.selectElement(find)
    },

    handleArrowLeft() {
      if (this.selectedInput <= 0) this.selectedInput = document.querySelectorAll(`.input-form:not(:disabled)`).length
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput - 1]
      this.selectElement(find)
    },

    handleArrowDown() {
      if (this.selectedInput + this.maxInputForm > this.maxInput) this.selectedInput = -(this.maxInput + 1 - this.selectedInput) + this.maxInputForm
      else this.selectedInput += this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    handleArrowUp() {
      if (this.selectedInput - this.maxInputForm < 0) this.selectedInput = this.maxInput + this.selectedInput + 1
      this.selectedInput -= this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    selectElement(el) {
      if (el) {
        if (el.querySelector('input')) el.querySelector('input').focus()
        else if (el.querySelector('button')) el.querySelector('button').focus()
      }
    },

    init() {
      if (this.selectedFarm.ponds.length) {
        if (this.selectedFarm.blocks.length) this.selectedBlock = this.selectedFarm.blocks[0]

        this.resetForm()
      }
    },
    resetForm() {
      this.data = []
      this.times = []
      this.selectedDate = new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' 07:00')

      for (let i = 0; i < this.filteredPondsInput.length; i++) {
        this.data.push({
          pond: this.filteredPondsInput[i],
          data_anco: [],
        })
      }
    },

    async handleSubmit() {
      this.$loading()

      let data = []
      let ponds = []

      data = this.filteredData.reduce((a, b) => {
        if (b.mbw && b.sr) {
          a.push(b)
          if (!ponds.find(f => f.pond_id === b.pond.pond_id)) ponds.push(b.pond)
        }
        return a
      }, data)

      for (let i = 0; i < ponds.length; i++) {
        let stock = await this.$store.dispatch('farm/getActiveStock', ponds[i].pond_id)
        if (stock) ponds[i].stock = stock
      }

      let payload = []
      let timediff = new Date().getTimezoneOffset() * -1

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let pond = ponds.find(e => e.pond_id === item.pond.pond_id)
        if (pond && pond.stock) {
          let stock = pond.stock

          let date = this.$timestamp(this.selectedDate)

          payload.push({
            farm_id: this.selectedFarm.farm_id,
            block_id: pond.block_id,
            pond_id: stock.pond_id,
            stock_id: stock.stock_id,
            mbw: item.mbw,
            sr: item.sr,
            num_of_shrimp: item.num_of_shrimp,
            total_weight: item.total_weight,
            sampling_date: date,
            kropos: item.kropos,
            molting: item.molting,
            warna_pucat: item.warna_pucat,
            blantik: item.blantik,
            kaki_merah: item.kaki_merah,
            ekor_merah: item.ekor_merah,
            created_at: date,
            photos: [],
            timediff,
          })
        }
      }

      await this.$store.dispatch('farm/addSampling', payload)

      this.$loading(false)
      this.resetForm()

      if (!this.embed) {
        this.$router.push('/farm/' + this.selectedFarm.farm_id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}

.input-form {
  min-width: 100px;
}

</style>
